import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { range } from 'ramda'

import Layout from '../components/layout'
import Seo from '../components/seo'
import { SupportHeader } from '../components/sectionHeader'
import HighlightSection from '../components/homeSections/HighlightSection'
import { getImage } from '../utils/gatsby-strapi-helpers'
import { Body, SpecialHeader2 } from '../components/shared/Text'
import { SecondaryButton } from '../components/shared/Buttons'
import { messages } from '../hooks/useMessages'
import Markdown from '../components/shared/Markdown'

const DownloadButton = ({ label, link }) => (
  <SecondaryButton className="w-fit" href={link}>
    {label}
  </SecondaryButton>
)

const Press = ({ pageContext, data }) => {
  const { locale } = pageContext
  const { strapiPress } = data

  const {
    title,
    description,
    picture,
    subtitle: {
      data: { subtitle },
    },
    category1,
    link1,
    category2,
    link2,
    category3,
    link3,
    category4,
    link4,
    category5,
    link5,
    category6,
    link6,
    categoryVideos,
    linkVideos1,
    linkVideos2,
    linkVideos3,
    linkVideos4,
    linkVideos5,
  } = strapiPress

  const categories = [
    { category: category1, link: link1 },
    { category: category2, link: link2 },
    { category: category3, link: link3 },
    { category: category4, link: link4 },
    { category: category5, link: link5 },
    { category: category6, link: link6 },
  ]

  const videos = [
    linkVideos1,
    linkVideos2,
    linkVideos3,
    linkVideos4,
    linkVideos5,
  ]
  const seo = {
    metaTitle: title,
    metaDescription: description,
  }

  const msg = messages(locale)

  return (
    <Layout pageContext={pageContext}>
      <Seo seo={seo} />
      <HighlightSection
        className="lg:!h-3/4"
        header={<SupportHeader title={title} paragraph={description} />}
        staticImage={
          <GatsbyImage
            image={getImage(picture)}
            alt={title}
            layout="fullWidth"
            className="max-h-[70vh]"
            backgroundColor="#161616"
          />
        }
      />
      <div className="container pt-10 text-center lg:max-w-[70vw] lg:pt-20 2xl:max-w-[55vw] 2xl:pt-[2.5rem]">
        <Body>
          <Markdown>{subtitle}</Markdown>
        </Body>
      </div>

      <div className="container pt-6 lg:pt-12 2xl:pt-20">
        {categories.map(({ category, link }) => (
          <div
            key={category}
            className="mb-12 flex flex-col items-center justify-center gap-8 text-center"
          >
            <SpecialHeader2>{category}</SpecialHeader2>
            {typeof link === 'string' ? (
              <DownloadButton label={msg('download')} link={link} />
            ) : (
              (link || []).map(({ label, url }) => (
                <DownloadButton key="url" label={label} link={url} />
              ))
            )}
          </div>
        ))}
        <div className="mb-12 flex flex-col items-center justify-center gap-8 text-center">
          <SpecialHeader2>{categoryVideos}</SpecialHeader2>
          {range(1, 6).map((n, idx) => (
            <div
              key={n}
              className="flex w-full flex-col items-center justify-center gap-4 md:flex-row md:gap-16"
            >
              <Body className="min-w-[11.25rem]">{`${msg(
                'video'
              )} #${n}`}</Body>
              <DownloadButton label={msg('download')} link={videos[idx]} />
            </div>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($locale: String) {
    strapiPress(locale: { eq: $locale }) {
      title
      description
      picture {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      subtitle {
        data {
          subtitle
        }
      }

      category1
      link1

      category2
      link2

      category3
      link3

      category4
      link4

      category5
      link5 {
        label
        url
      }

      category6
      link6

      categoryVideos
      linkVideos1
      linkVideos2
      linkVideos3
      linkVideos4
      linkVideos5
    }
  }
`

export default Press
